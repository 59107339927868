<!-- 供应商供货表-详情 -->
<template>
  <div class="pagePadding">
    <span class="pageBtn finger btnReset" @click="back">返回</span>
    <span class="pageBtn finger btnSure marginLeft20">
      <span v-if="!Loagin" class="pageBtn finger btnSure" @click="Export">导出</span>
      <span v-if="Loagin" class="pageBtn finger btnSure">
        <Spin v-if="Loagin" style="display: inline-block; color: white;">
          <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
        </Spin>
      </span>
    </span>
    <div class="tabelDiv tabDiv" ref="tabDiv" style="margin-top: 20px;">
      <!-- <Spin fix v-if="tabloading"></Spin> -->
      <Table :productList="popupListColumns" show-summary :summary-method="handleSummary" :productData="listData" :pages="queryFrom" :total="total" totalText="条记录" @change-page="changePage" :loading="loading" :isLoad="loading">
        <template slot-scope="{ row, index }" slot="do">
          <div><span class="finger color389" @click="gotoThirdDetail(row, index)">详情</span></div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import selfTable from '@/components/table'
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'monthlySupplyListSec',
  components: {
    selfTable,
    TableSetup,
    Table,
  },
  data() {
    return {
      Loagin: false,
      loading: true,
      option_page: '28',
      // 二级详情弹窗
      popupListData: [],
      // 二级详情表头
      popupListColumns: [
        {
          title: '序号',
          type: 'index',
          key: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 90,
        },
        {
          title: '日期',
          key: 'create_time',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '供货总量',
          key: 'purchase_quantity',
          align: 'center',
          minWidth: 110,
        },
        {
          title: '供货金额',
          key: 'purchase_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.purchase_amount ? '￥' + param.row.purchase_amount : 0)])
          },
        },
        {
          title: '供货不含税金额',
          key: 'no_tax_purchase_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_purchase_amount ? '￥' + param.row.no_tax_purchase_amount : 0)])
          },
        },
        {
          title: '采购比重',
          key: 'purchase_proportion',
          align: 'center',
          minWidth: 90,
          render: (h, param) => {
            let money
            if (!param.row.purchase_quantity) {
              money = ''
            } else {
              money = ((Number(param.row.purchase_quantity) / this.purchase_quantity_tatol) * 100).toFixed(4)
            }
            money = money ? money : 0
            return h('span', money + '%')
          },
        },
        {
          title: '销售总量',
          key: 'sale_quantity',
          align: 'center',
          minWidth: 110,
        },
        {
          title: '销售金额',
          key: 'sale_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.sale_amount ? '￥' + param.row.sale_amount : 0)])
          },
        },
        {
          title: '销售不含税金额',
          key: 'no_tax_sale_amount',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.no_tax_sale_amount ? '￥' + param.row.no_tax_sale_amount : 0)])
          },
        },
        {
          title: '利润',
          key: 'profit',
          align: 'center',
          minWidth: 110,
          render: (h, param) => {
            return h('div', [h('span', param.row.profit ? '￥' + param.row.profit : 0)])
          },
        },
        {
          title: '利润率',
          key: 'profit_margin',
          align: 'center',
          minWidth: 110,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 70,
          maxWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.gotoThirdDetail(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 表格数据
      listData: [],
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      // 查询参数
      queryFrom: {
        product_model_code: '',
        product_id: '',
        code_id: '',
        item_number: '',
      },
      supplier_id: '', // 产品id
      // 暂存采购金额总合
      totalPurchaseAmount: 0,
      totalPurchaseQuantity: 0,
      totalSaleQuantity: 0,
      totalSaleAmount: 0,
      supplied_varieties_tatol: 0, // 品种总数
      purchase_quantity_tatol: 0, // 采购数量总数
      purchase_amount_tatol: 0, // 采购金额总数
      sale_quantity_tatol: 0, // 销售数量总数
      sale_amount_tatol: 0, // 销售金额总数
      profit_total: 0, // 利润总数
      total_no_tax_purchase_amount: 0, // 不含税采购总金额
      total_no_tax_sale_amount: 0, // 不含税销售总金额
      daochu: {},
    }
  },
  methods: {
    back() {
      this.$router.push({
        path: '/monthlySupplyList',
      })
    },
    // 供应商二级页面导出
    Export() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      this.$http.downFile(this.$api.exportsupplierMounthSupplySecond, this.daochu, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商月度供货表详情.xlsx'
        aLink.click()
        this.Loagin = false
      })
    },
    gotoThirdDetail(row, index) {
      this.$router.push({
        path: '/monthlySupplyListThi',
        query: {
          supplier_id: this.$route.query.supplier_id,
          create_time: row.create_time,
        },
      })
    },
    // 处理金额
    OpearateMoney(item) {
      let num = item
      num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
      var valueArr = num.split('.')[0].split('').reverse()
      const valueFloat = num.split('.')[1]
      let valueString = ''
      for (let i = 0; i < valueArr.length; i++) {
        valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
      }
      const money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      return '¥' + money
    },
    changePage(e) {
      let list = this.queryFrom.specification_id_str ? this.queryFrom.specification_id_str.join(',') : ''
      this.pages.page = e
      let searchForm = {}
      searchForm.page = this.pages.page
      searchForm.rows = this.pages.rows
      searchForm.supplier_id = this.$route.query.supplier_id
      searchForm.product_model_code = this.queryFrom.product_model_code
      searchForm.product_id = this.queryFrom.product_id
      searchForm.model_id = this.queryFrom.code_id
      searchForm.item_number = this.queryFrom.item_number
      searchForm.specification_id_str = list
      this.getTableList(searchForm)
    },
    // 表格的合计
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          let v = ''
          if (key === 'purchase_quantity' || key === 'profit' || key === 'purchase_amount' || key === 'sale_amount' || key === 'sale_quantity' || key === 'supplied_varieties' || key === 'no_tax_purchase_amount' || key === 'no_tax_sale_amount') {
            if (key === 'purchase_quantity') {
              v = this.purchase_quantity_tatol
            }
            if (key === 'profit') {
              v = '¥' + this.profit_total
            }
            if (key === 'purchase_amount') {
              v = '¥' + this.purchase_amount_tatol
            }
            if (key === 'sale_amount') {
              v = '¥' + this.sale_amount_tatol
            }
            if (key === 'sale_quantity') {
              v = this.sale_quantity_tatol
            }
            if (key === 'supplied_varieties') {
              v = this.supplied_varieties_tatol
            }
            if (key === 'no_tax_purchase_amount') {
              v = '¥' + this.total_no_tax_purchase_amount
            }
            if (key === 'no_tax_sale_amount') {
              v = '¥' + this.total_no_tax_sale_amount
            }
          }
          sums[key] = {
            key,
            value: v,
          }
        } else {
          let v = ''
          if (key === 'purchase_quantity') {
            v = this.purchase_quantity_tatol
          }
          if (key === 'profit') {
            v = '¥' + this.profit_total
          }
          if (key === 'purchase_amount') {
            v = '¥' + this.purchase_amount_tatol
          }
          if (key === 'supplied_varieties') {
            v = this.supplied_varieties_tatol
          }
          if (key === 'sale_amount') {
            v = '¥' + this.sale_amount_tatol
          }
          if (key === 'sale_quantity') {
            v = this.sale_quantity_tatol
          }
          if (key === 'no_tax_purchase_amount') {
            v = '¥' + this.total_no_tax_purchase_amount
          }
          if (key === 'no_tax_sale_amount') {
            v = '¥' + this.total_no_tax_sale_amount
          }
          sums[key] = {
            key,
            value: v,
          }
        }
      })
      return sums
    },
    // 获取表格数据
    getTableList(item) {
      this.loading = true
      this.$http.get(this.$api.supplierMounthSupplyListSec, item, true).then(res => {
        if (res.status) {
          this.listData = res.data.result
          this.totalPurchaseAmount = res.data.total_purchase_amount
          this.totalPurchaseQuantity = res.data.total_purchase_quantity
          this.totalSaleQuantity = res.data.total_sale_quantity
          this.totalSaleAmount = res.data.total_sale_amount
          this.loading = false
          this.listData.forEach((item, index) => {
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD') : ''
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : ''
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : ''
            // 判断是录入的是年月还是年月日
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : ''
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : ''
            item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : ''
            if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.business_license_valid_period = '长期'
            } else {
              item.business_license_valid_period = ''
            }
            if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
              item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : ''
            } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
              item.record_valid_period = '长期'
            } else {
              item.record_valid_period = ''
            }
            item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : ''
            item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : ''
            item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : ''
            item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : ''
            item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : ''
            item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : ''
            item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : ''
            // item.purchase_amount = this.OpearateMoney(item.purchase_amount)
            // item.sale_amount = this.OpearateMoney(item.sale_amount)
          })
          this.total = res.data.total
        }
      })
      this.$http.get(this.$api.supplierMounSupplySecTotal, item, false).then(res => {
        this.supplied_varieties_tatol = res.data.supplied_varieties_tatol
        this.purchase_quantity_tatol = res.data.purchase_quantity_tatol
        this.purchase_amount_tatol = res.data.purchase_amount_tatol
        this.sale_quantity_tatol = res.data.sale_quantity_tatol
        this.sale_amount_tatol = res.data.sale_amount_tatol
        this.profit_total = res.data.profit_total
        this.total_no_tax_purchase_amount = res.data.total_no_tax_purchase_amount
        this.total_no_tax_sale_amount = res.data.total_no_tax_sale_amount
      })
    },
  },
  created() {
    let data = JSON.parse(window.localStorage.getItem('monthSup'))
    this.supplier_id = data.supplier_id
    this.daochu = JSON.parse(JSON.stringify(data))
    data.page = 1
    data.rows = 10
    this.getTableList(data)
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
